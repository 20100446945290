import styled, { css } from 'styled-components';

export const ReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;
export const ReportHeader = styled.h4`
  ${({ theme: { color, font } }) => css`
    color: ${color.midnight5};
    ${font.label}
  `};
`;
export const ReportBody = styled.div`
  ${({ theme: { color, font } }) => css`
    color: ${color.midnight10};
    font-size: ${font.body};
  `};
`;
export const ReportSection = styled.div``;
export const ProgressBarWrapper = styled.div`
  max-width: 200px;
  margin-bottom: 0.5rem;
`;
export const ReportAIPill = styled.div`
  ${({ theme: { color, fontSize, borderRadius } }) => css`
    margin-left: auto;
    border-radius: ${borderRadius.sm};
    background-color: ${color.turquoise20};
    font-size: ${fontSize.md};
    color: ${color.midnight};
    padding: 0.1rem 0.5rem;
  `};
`;
