import React, { FC, useCallback, useEffect, useState } from 'react';
import * as Styled from './CoachingSessionReport.styled';
import { ProgressBar } from '@virtidev/toolbox';

/**
 * @typedef {import('../../../../../../models/virtualhuman.types').CoachingSessionReport} CoachingSessionReport
 * @typedef {import('../../../../../../models/virtualhuman.types').CoachingSessionReportObjective} CoachingSessionReportObjective
 */

/** @type {FC<{report: CoachingSessionReport & {objectiveReports: CoachingSessionReportObjective[]}}>} */
const CoachingSessionReport = ({ report }) => {
  return (
    <Styled.ReportWrapper>
      <Styled.ReportAIPill>Generated with AI</Styled.ReportAIPill>
      <Styled.ReportSection>
        <Styled.ReportHeader>Overall Analysis</Styled.ReportHeader>
        <Styled.ReportBody>{report.overallAnalysis}</Styled.ReportBody>
      </Styled.ReportSection>
      <Styled.ReportSection>
        <Styled.ReportHeader>Areas of Improvement</Styled.ReportHeader>
        <Styled.ReportBody>{report.areasOfImprovement}</Styled.ReportBody>
      </Styled.ReportSection>
      {report.objectiveReports.map((obj) => (
        <Styled.ReportSection key={obj.id}>
          <Styled.ProgressBarWrapper>
            <ProgressBar
              color="turquoise"
              progress={obj.rating}
              max={100}
              height={4}
              labelText={`${obj.rating}%`}
            />
          </Styled.ProgressBarWrapper>
          <Styled.ReportHeader>{obj.name}</Styled.ReportHeader>
          <Styled.ReportBody>{obj.reason}</Styled.ReportBody>
        </Styled.ReportSection>
      ))}
    </Styled.ReportWrapper>
  );
};

export default CoachingSessionReport;
