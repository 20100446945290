import React, { FC, useCallback, useEffect, useState } from 'react';
import * as Styled from './ScoreOverview.styled';
import CoachingSessionReportGenerator from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/CoachingSessionReportGenerator/CoachingSessionReportGenerator';
import { GetFormattedTimeLong } from '@base/utility/TimeFormatting';
import ObjectiveGroupResult from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/ObjectiveGroupResult/ObjectiveGroupResult';
import CoachingSessionReport from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/CoachingSessionReport/CoachingSessionReport';
import ScorePercentCircle from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/ScorePercentCircle/ScorePercentCircle';
import AnalyticsSummaryItem from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/AnalyticsSummaryItem/AnalyticsSummaryItem';
import { ToDecimalPlace } from '@base/utility/NumberFormatting';
import { clamp } from 'lodash';

/**
 * @typedef {import('../../../../models/vh-server/vh-server-objective.types').VHServerObjectiveGroup} VHServerObjectiveGroup
 * @typedef {import('../../../../models/vh-server/vh-server-objective.types').VHServerObjective} VHServerObjective
 * @typedef {import('../../../../models/vh-server/vh-server-virtualhuman.types').VHServerVirtualHuman} VHServerVirtualHuman
 * @typedef {import('../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReport} CoachingSessionReport
 * @typedef {import('../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReportObjective} CoachingSessionReportObjective
 * @typedef {import('@core/models/virtualhuman.types').VirtualHuman} VirtualHuman
 * @typedef {import('@core/models/vh-server/vh-server-assessment.types').HitObjectives} HitObjectives
 *
 */

/**
 * @type { FC<{
 *    objectiveGroups: VHServerObjectiveGroup[];
 *    allObjectives: VHServerObjective[];
 *    timeSpent: number;
 *    diagnosisSuccess: boolean;
 *    vhDBVH:VHServerVirtualHuman;
 *    ssVH: VirtualHuman;
 *    hitObjectivesData: HitObjectives;
 *    pointsScored: number;
 *    pointsPossible: number;
 *    assessmentId: number;
 *    hideScores: boolean;
 *    easyMode: boolean;
 *    shownHintObjectiveIds: number[];
 *    footer?: React.ReactNode;
 *    sessionReport?: CoachingSessionReport & {objectiveReports: CoachingSessionReportObjective[];}
 * }>}
 */
const ScoreOverview = ({
  objectiveGroups,
  allObjectives,
  timeSpent,
  diagnosisSuccess,
  vhDBVH,
  ssVH,
  hitObjectivesData,
  pointsScored,
  pointsPossible,
  assessmentId,
  hideScores,
  easyMode,
  shownHintObjectiveIds,
  footer,
  sessionReport,
}) => {
  const [report, setReport] = useState(
    /** @type {CoachingSessionReport & {objectiveReports: CoachingSessionReportObjective[]} | null} */ (
      sessionReport ?? null
    )
  );

  useEffect(() => {
    setReport(sessionReport ?? null);
  }, [sessionReport]);

  const handleReportGenerated = useCallback((newReport) => {
    setReport(newReport);
  }, []);
  const timeSuccess =
    timeSpent < ssVH.TimeLimit * 60 * 1000 || ssVH.TimeLimit === 0;
  const scorePercentageFraction = !pointsScored
    ? 0
    : pointsScored / pointsPossible;
  const clampedScorePercentageFraction = clamp(scorePercentageFraction, 0, 1);
  const scorePercentage = ToDecimalPlace(
    clampedScorePercentageFraction * 100,
    1
  );

  return (
    <Styled.ScoreOverviewWrapper>
      <Styled.ScoreOverviewContent>
        <div>
          {/* time and diagnosis results */}
          {/* <Styled.SummaryText>Performance Report</Styled.SummaryText> */}
          <Styled.MainSummary
            $vhType={ssVH.Type}
            $pointsPossible={pointsPossible}
            $disableDiagnosis={vhDBVH.disableDiagnosis}
          >
            {pointsPossible > 0 && (
              <>
                {!hideScores && (
                  <Styled.SummaryCircle>
                    <ScorePercentCircle
                      scorePercent={ToDecimalPlace(
                        clampedScorePercentageFraction,
                        1
                      )}
                    />
                    <Styled.AbsoluteSummaryItem>
                      <AnalyticsSummaryItem
                        label="Score"
                        value={`${scorePercentage}%`}
                        iconName="medal"
                      />
                    </Styled.AbsoluteSummaryItem>
                  </Styled.SummaryCircle>
                )}
                <Styled.BlueSummaryCircle>
                  <AnalyticsSummaryItem
                    label="Time taken"
                    value={GetFormattedTimeLong(timeSpent)}
                    iconName="clock"
                  />
                </Styled.BlueSummaryCircle>
                <Styled.TurquoiseSummaryCircle>
                  <AnalyticsSummaryItem
                    label="Objective points"
                    value={`${pointsScored}`}
                    iconName="diamond"
                  />
                </Styled.TurquoiseSummaryCircle>
              </>
              // <Styled.BoxResult $success={true}>
              //   <Styled.PointsText>Points:</Styled.PointsText>
              //   <Styled.PillDataSection>
              //     <PillData>
              //       {/* only use convo points in non-medical */}
              //       {pointsScored}/{pointsPossible}
              //     </PillData>
              //     <PillData>
              //       {ToDecimalPlace((pointsScored / pointsPossible) * 100, 2)}%
              //     </PillData>
              //   </Styled.PillDataSection>
              // </Styled.BoxResult>
            )}
          </Styled.MainSummary>
          {/* conversation objectives results */}
          {objectiveGroups.map((objectiveGroup) => (
            <ObjectiveGroupResult
              key={objectiveGroup.id}
              heading={objectiveGroup.name}
              allObjectives={allObjectives}
              hitObjectives={hitObjectivesData?.objectives ?? []}
              feedbackText={objectiveGroup.feedbackText ?? ''}
              easyMode={easyMode}
              shownHintObjectiveIds={shownHintObjectiveIds}
              groupId={objectiveGroup.id}
            />
          ))}
        </div>
        {!report && (
          <CoachingSessionReportGenerator
            onReportGenerated={handleReportGenerated}
            assessmentId={assessmentId}
          />
        )}
        {report && <CoachingSessionReport report={report} />}
      </Styled.ScoreOverviewContent>
      {footer && <Styled.Footer>{footer}</Styled.Footer>}
    </Styled.ScoreOverviewWrapper>
  );
};

ScoreOverview.propTypes = {};

export default ScoreOverview;
