import styled, { css } from 'styled-components';

export const MenuItemText = styled.span`
  flex: 1;
  text-align: start;
`;

export const MenuItemButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing.sm};
    cursor: pointer;
    background: none;
    border: 0;
    padding: 0 ${theme.spacing.md};
    margin-bottom: ${theme.spacing.sm};

    :last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: ${theme.color.primary};
    }
  `}
`;
